.dropzone {
	height: 15vh;
	border-width: 1px;
	background-color: rgba(102, 102, 102, 0.2);
	border-radius: 5px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-evenly;
}
.listItem {
	flex: 0 1 15%;
	justify-content: space-between;
	display: flex;
	line-height: 28pt;
	border-bottom: 1px solid;
	&:hover {
		cursor: pointer;
	}
}
.listHeader {
	font-weight: bold;
}
.selected {
	background: #f1404b;
	color: #f4f5f9;
}
.part {
	display: flex;
	align-self: flex-start;
	min-width: 5vw;
}